.app-container {
  position: relative;
  width: 100%;
  height: 62.5vw;
  overflow: hidden;
}

.spacer {
  flex-grow: 1;
}
