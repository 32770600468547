@font-face {
  font-family: 'Etelka';
  font-style: normal;
  font-weight: 400;
  src: url('~assets/fonts/EtelkaText.otf') format('opentype');
}

@font-face {
  font-family: 'Etelka';
  font-style: normal;
  font-weight: 500;
  src: url('~assets/fonts/EtelkaMedium.otf') format('opentype');
}

@font-face {
  font-family: 'EtelkaPro';
  font-style: normal;
  font-weight: 500;
  src: url('~assets/fonts/EtelkaMediumBold.otf') format('opentype');
}
